'use client'

import Link from 'next/link'
import { useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import { links } from '../lib/models'
import { pathnameProps } from '../lib/utils'
import { useAuth } from '@/context/AuthContext'

export default function Burger() {
    let html: HTMLHtmlElement
    const [isOpen, setIsOpen] = useState(false)
    const router = useRouter()
    const pathname = usePathname()
    const back = pathnameProps(pathname).back
    const { user } = useAuth()

    useEffect(() => {
        html = document.getElementsByTagName('html')[0]
    })

    const handleClick = () => {
        if (back) router.back()
        else {
            setIsOpen(!isOpen)
            if (!isOpen) html.classList.add('lock-scroll')
            else html.classList.remove('lock-scroll')
        }
    }

    return (
        <div
            className="flex h-full items-start items-center lg:hidden"
            onClick={() => handleClick()}
        >
            <div
                className={
                    isOpen
                        ? 'active-hamburger'
                        : back
                          ? 'back-button'
                          : 'hamburger'
                }
            />
            <p className={back ? 'back-text-active' : 'back-text'}>Indietro</p>
            <div className={isOpen ? 'active-sidenav' : 'sidenav'}>
                <ul>
                    <li>
                        <Link href="/access" hidden={user != undefined}>
                            <div className="flex h-16 w-full items-center justify-start border-b border-gray-400">
                                <i
                                    className={`icon bi-person-add p-4 text-3xl`}
                                />
                                <p className="text-lg font-semibold">Accedi</p>
                            </div>
                        </Link>
                    </li>
                    {links.map((link) => (
                        <li key={link.name}>
                            <Link
                                onClick={() => handleClick()}
                                href={link.href}
                                hidden={
                                    link.needUser ? user === undefined : false
                                }
                            >
                                <div className="flex h-16 w-full items-center justify-start border-b border-gray-400">
                                    <i
                                        className={`icon p-4 text-3xl bi-${link.icon}`}
                                    />
                                    <p className="text-lg font-semibold">
                                        {link.name}
                                    </p>
                                </div>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
            <div className={isOpen ? 'active-shadow' : 'shadow'} />
        </div>
    )
}
