'use client'

import Link from 'next/link'
import clsx from 'clsx'
import { usePathname } from 'next/navigation'
import ClientDropdown from './client-dropdown'
import { useAuth } from '@/context/AuthContext'

export default function NavLinks() {
    const pathname = usePathname()
    const { user } = useAuth()

    const partnerItems = [
        {
            title: 'Associazioni',
            href: '/partners',
        },
        {
            title: 'Sponsors',
            href: '/sponsors',
        },
    ]

    const gamesItems = [
        {
            title: 'Giochi di Ruolo',
            href: '/games/rpg',
        },
        {
            title: 'Giochi da Tavolo',
            href: '/games/ttg',
        },
    ]

    return (
        <ul className="hidden items-center gap-x-6 text-white lg:flex">
            <li>
                <Link
                    href="/map"
                    className={clsx({ 'font-bold': pathname === '/map' })}
                >
                    Mappa
                </Link>
            </li>
            <li>
                <ClientDropdown items={partnerItems} text="Chi partecipa?" />
            </li>
            <li>
                <ClientDropdown items={gamesItems} text="Giochi" />
            </li>
            <li>
                <Link
                    href="/conferences?day=2"
                    className={clsx({
                        'font-bold': pathname.includes('conferences'),
                    })}
                >
                    Conferenze
                </Link>
            </li>
            <li>
                <Link
                    href="/events?day=2"
                    className={clsx({
                        'font-bold': pathname.includes('events'),
                    })}
                >
                    Eventi
                </Link>
            </li>
            {user != undefined && (
                <li>
                    <Link
                        href="/profile"
                        className={clsx({
                            'font-bold': pathname.includes('profile'),
                        })}
                    >
                        Area Personale
                    </Link>
                </li>
            )}
            {user === undefined && (
                <li>
                    <Link
                        href="/access"
                        className="text-lg font-semibold text-[#76B949]"
                    >
                        Accedi
                    </Link>
                </li>
            )}
        </ul>
    )
}
