'use client'

import {
    Dropdown,
    DropdownTrigger,
    DropdownMenu,
    DropdownItem,
} from '@nextui-org/dropdown'
import { Button } from '@nextui-org/button'
import { useState } from 'react'

export default function ClientDropdown({
    items,
    text,
}: Readonly<{ items: { title: string; href: string }[]; text: string }>) {
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Dropdown
            isOpen={isOpen}
            onMouseEnter={() => {
                setIsOpen(true)
            }}
            onMouseLeave={() => {
                setIsOpen(false)
            }}
            offset={-3}
        >
            <DropdownTrigger>
                <Button
                    className="text-md group bg-transparent p-0 text-white"
                    radius="none"
                    onMouseEnter={() => {
                        setIsOpen(true)
                    }}
                    onMouseLeave={() => {
                        setIsOpen(false)
                    }}
                >
                    {text}
                    <i className="bi-chevron-down" />
                </Button>
            </DropdownTrigger>
            <DropdownMenu items={items}>
                {(item) => (
                    <DropdownItem key={item.title} href={item.href}>
                        {item.title}
                    </DropdownItem>
                )}
            </DropdownMenu>
        </Dropdown>
    )
}
