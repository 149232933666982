'use client'

import { useSearchParams, useRouter } from 'next/navigation'
import { useState } from 'react'

export default function Search() {
    const searchParams = useSearchParams()
    const router = useRouter()
    const [isOpen, setIsOpen] = useState(false)
    let params = searchParams.get('query')?.toString() ?? ''

    function readInput(term: string) {
        params = encodeURIComponent(term)
    }

    function changeSearchState(value?: boolean) {
        setIsOpen(value || !isOpen)
    }

    function handleSearch(mobile?: boolean) {
        if (params != '') {
            if (mobile) changeSearchState(false)
            router.push(`/search?query=${params}`)
        } else if (params === '') {
            alert(
                'Barra di ricerca vuota.\nInserisci del testo per eseguire la ricerca.'
            )
        }
    }

    return (
        <>
            <div
                className="fixed right-4 top-5 z-10 flex h-10 w-10 items-center justify-center rounded-full bg-white lg:hidden"
                onClick={() => {
                    changeSearchState()
                }}
            >
                <i className="bi-search text-2xl" />
            </div>
            <div className="right-4 z-10 ms-4 hidden w-fit lg:flex">
                <div className="flex h-10 max-w-full justify-between bg-white">
                    <input
                        className="flex items-center justify-start p-4 text-lg"
                        placeholder="Cerca..."
                        onChange={(e) => readInput(e.target.value)}
                        onKeyDownCapture={(e) => {
                            if (e.key === 'Enter') handleSearch()
                        }}
                        defaultValue=""
                    />
                    <button
                        className="group flex h-full w-10 items-center justify-center transition duration-100 ease-in-out hover:bg-[#4FC2EB]"
                        onClick={() => {
                            handleSearch()
                        }}
                    >
                        <i className="bi-search text-2xl text-black transition duration-100 ease-in-out group-hover:text-white" />
                    </button>
                </div>
            </div>
            <div className={isOpen ? 'mobile-search' : 'mobile-search-open'}>
                <div className="h-full w-full">
                    <div className="flex h-20 justify-between">
                        <input
                            className="flex w-full items-center justify-start p-4 text-lg"
                            placeholder="Cerca..."
                            onChange={(e) => readInput(e.target.value)}
                            onKeyDownCapture={(e) => {
                                if (e.key === 'Enter') handleSearch()
                            }}
                            defaultValue={searchParams.get('query')?.toString()}
                        />
                        <div
                            className="flex items-center p-4"
                            onClick={() => changeSearchState()}
                        >
                            <i className="bi-x-lg text-3xl text-black" />
                        </div>
                    </div>
                    <div className="flex h-full justify-center bg-gray-100">
                        <button
                            className="mobile-button-green fixed bottom-10"
                            onClick={() => {
                                handleSearch(true)
                            }}
                        >
                            ESEGUI LA RICERCA
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
